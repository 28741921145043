import StoreLayout from '../components/store/StoreLayout'

type Props = {}

export default function StoreScreen({ }: Props) {

    return (
        <>
            <StoreLayout />
        </>
    )
}